import React from 'react'
import { useState } from 'react';
import Swal from 'sweetalert2';

const useCartContant = () => {
    const [cartproducts, setcartproducts] = useState({ cart_items: [], total_cart_value: 0 });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchCartProducts = async (coupon_id, fast_delivery, coupon_code) => {
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        const common_id = localStorage.getItem('common_id') ? localStorage.getItem('common_id') : '';
        try {
            const response = await fetch(`https://backend.amjgems.com/api/cart/items`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify({ common_id: common_id, user_id: parseInt(user_id), coupon_id: coupon_id ? coupon_id : "null", coupon_code: coupon_code ? coupon_code : "null", fast_delivery: fast_delivery ? fast_delivery : false }),
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (!data.valid_coupon && data.cart_items.length > 0) {
                Swal.fire({
                    title: 'Invalid Coupon : ' + coupon_code,
                });
            }
            setcartproducts(data);
            setError(null);
        } catch (error) {
            console.log(error);

        } finally {
            setLoading(false);
        }
    };

    return { cartproducts, loading, error, fetchCartProducts };
}

export default useCartContant