import React from 'react'
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AddReviewModal from './AddReviewModal';
const Showallreviews = () => {

    const { product_code } = useParams(); // Correctly extract product_code from useParams
    //Review Modal
    const [reviewShow, setreviewShow] = useState(false);

    const handleClose = () => {
        setreviewShow(false);
    };
    const handleShowReview = () => setreviewShow(true);

    const [reviews, setReviews] = useState([]);
    const fetchreviews = async () => {
        try {
            const response = await fetch(`https://backend.amjgems.com/api/reviews/${product_code}`, {
                method: 'GET',
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setReviews(data);
        } catch (error) {
            console.log(error.message);
        }
    };
    useEffect(() => {
        if (reviewShow == false) {
            fetchreviews();
        }

    }, [reviewShow])

    const timeSince = (date) => {
        const now = new Date();
        const elapsed = now - new Date(date);

        const seconds = Math.floor(elapsed / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);

        if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
        if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
    };
    return (

        <div id='review'>
            <button onClick={handleShowReview} className='reviewbut'>Write a Riview</button>

            {reviews.map((review) => (

                <div class="review-container">
                    <span className="author-name">{review.user_name}</span>
                    <div className="review-content">
                        <div>{review.review}
                        </div>
                    </div>
                    <div class="author">
                        <div className="rating">
                            <span className={1 <= review.rating ? "star" : "nostar"}>★</span>
                            <span className={2 <= review.rating ? "star" : "nostar"}>★</span>
                            <span className={3 <= review.rating ? "star" : "nostar"}>★</span>
                            <span className={4 <= review.rating ? "star" : "nostar"}>★</span>
                            <span className={5 <= review.rating ? "star" : "nostar"}>★</span>
                        </div>
                        {/* <span className="author-verified">&#10004; User_name {review.user_id}</span> */}
                        <span className="author-date">{timeSince(review.created_at)}</span>
                        <div id='reviewimages'>
                            {review?.review_image_1 && <img src={`https://backend.amjgems.com/${review?.review_image_1}`} alt="" />}
                            {review?.review_image_2 && <img src={`https://backend.amjgems.com/${review?.review_image_2}`} alt="" />}

                        </div>
                    </div>
                </div>

            ))}
            <AddReviewModal show={reviewShow} onClose={handleClose} product_id={product_code} />
        </div>
    )
}

export default Showallreviews