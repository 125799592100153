import React, { useEffect, useState } from 'react';
import './Your_Orders.scss';
import { Link } from 'react-router-dom';

const Your_Orders = () => {
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState(null);

    const fetchOrders = async () => {
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') || '';

        // Example API endpoint
        const apiEndpoint = `https://backend.amjgems.com/api/fetch-orders`;

        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Using Bearer token authentication
                },
                body: JSON.stringify({ user_id: user_id })
            });

            if (response.ok) {
                const result = await response.json();
                setOrders(result.data); // Assuming result contains an 'orders' field
            } else {
                const error = await response.json();
                setError(error.message); // Set error message
            }
        } catch (error) {
            setError('Network error: ' + error.message); // Handle network error
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchOrders();
    }, []);

    return (
        <div className="orders-container">
            <h1 className="orders-title">Your Orders</h1>
            {error && <div className="orders-error">{error}</div>}
            <ul className="orders-list">
                {orders?.length > 0 && orders?.map((order) => (
                    <div className="order-item">
                        <Link to={`order/${order.order.order_id}`} key={order.order.order_id}>
                            <li className="order-detail">
                                <strong>Order ID: </strong>
                                <span style={{ color: 'blue' }}>
                                    {order.order.order_id}
                                </span>
                            </li>
                            <li className="order-detail">
                                <strong>Payment Status: </strong>
                                <span className={order.order.payment_status.toLowerCase()}>
                                    {order.order.payment_status}
                                </span>
                            </li>
                            <li className="order-detail">
                                <strong>Order Status: </strong>
                                <span className={order.order.order_status.toLowerCase()}>{order.order.order_status}</span>
                            </li>

                            <li className="order-detail">
                                <strong>Payment Method:</strong> {order.order.payment_method}
                            </li>
                            <li className="order-detail">
                                <strong>Subtotal:</strong> $ {order.order.subtotal}
                            </li>
                            <li className="order-detail">
                                <strong>Transaction ID:</strong> {order.order.transaction_id}
                            </li>
                        </Link>
                        {order?.order?.order_receipt &&
                            <a href={`https://backend.amjgems.com/${order.order.order_receipt}`} target="_blank" rel="noopener noreferrer">
                                <button className="product-button">Show Receipt</button>
                            </a>
                        }
                    </div>
                ))}
            </ul>
        </div>

    );
};

export default Your_Orders;
