import React, { useEffect } from 'react'
import { useState } from 'react';
import { Country, State, City } from 'country-state-city';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Select from 'react-select';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

const EditProfileModal = ({ show, onClose }) => {


    const setFormx = (Address) => {
        setFormData({
            ...formData,
            name: Address.name || '',
            address: Address.address || '',
            city: Address.city || '',
            state: Address.state || '',
            country: Address.country || '',
            pincode: Address.pincode || '',
            contact_no: Address.contact_no || ''
        });
        setPhone(Address.contact_no || '');

        const countryOption = Country.getAllCountries().find(country => country.name === Address.country);
        setSelectedCountry(countryOption ? { value: countryOption.isoCode, label: countryOption.name } : null);

        if (countryOption) {
            const statesList = State.getStatesOfCountry(countryOption.isoCode);
            setStates(statesList.map(state => ({
                value: state.isoCode,
                label: state.name
            })));

            const stateOption = statesList.find(state => state.name === Address.state);
            setSelectedState(stateOption ? { value: stateOption.isoCode, label: stateOption.name } : null);

            if (stateOption) {
                const citiesList = City.getCitiesOfState(countryOption.isoCode, stateOption.isoCode);
                setCities(citiesList.map(city => ({
                    value: city.name,
                    label: city.name
                })));

                const cityOption = citiesList.find(city => city.name === Address.city);
                setSelectedCity(cityOption ? { value: cityOption.isoCode, label: cityOption.name } : null);
            }
        }
    }

    useEffect(() => {
        if (show) {

            // Define the async function inside useEffect
            const fetchUserData = async () => {
                const token = localStorage.getItem('token');
                const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';

                try {
                    const response = await fetch(`https://backend.amjgems.com/api/user/${user_id}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}` // Using Bearer token authentication
                        },
                    });
                    const data = await response.json();
                    setFormx(data.user);
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            };

            fetchUserData();
        }
    }, [show])

    const [phone, setPhone] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [formData, setFormData] = useState({
        user_id: localStorage.getItem('user_id'),
        name: '',
        address: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
        contact_no: ''
    });

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        const statesList = State.getStatesOfCountry(selectedOption.value);
        setStates(statesList.map(state => ({
            value: state.isoCode,
            label: state.name
        })));
        setCities([]);
        setFormData(prevData => ({
            ...prevData,
            country: selectedOption.label
        }));
    };

    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        const citiesList = City.getCitiesOfState(selectedCountry.value, selectedOption.value);
        setCities(citiesList.map(city => ({
            value: city.name,
            label: city.name
        })));
        setFormData(prevData => ({
            ...prevData,
            state: selectedOption.label
        }));
    };

    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
        setFormData(prevData => ({
            ...prevData,
            city: selectedOption.label
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handlePhoneChange = (value) => {
        setPhone(value);
        setFormData(prevData => ({
            ...prevData,
            contact_no: value
        }));
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        // Example API endpoint
        const apiEndpoint = 'https://backend.amjgems.com/api/user';

        try {
            const response = await fetch(apiEndpoint, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                const result = await response.json();
                onClose();
                Swal.fire({
                    title: 'Profile Updated Successful!',
                })
            }
        } catch (error) {
            // Handle network error (e.g., show an error message)
        }
    };
    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    Update Form for {formData.name}
                </Modal.Header>
                <Modal.Body>
                    <section className="checkout-shipping-info">
                        <h2 className="section-title">Profile Update</h2>
                        <form className="shipping-form" onSubmit={handleSubmit}>
                            <label htmlFor="name" className="form-label">Full Name:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className="form-input"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />

                            <label htmlFor="address" className="form-label">Address :</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                className="form-input"
                                value={formData.address}
                                onChange={handleInputChange}
                                required
                            />

                            <label htmlFor="country" className="form-label">Country:</label>
                            <Select
                                id="country"
                                name="country"
                                options={Country.getAllCountries().map(country => ({
                                    value: country.isoCode,
                                    label: country.name
                                }))}
                                className="form-select"
                                onChange={handleCountryChange}
                                value={selectedCountry}
                            />

                            <label htmlFor="state" className="form-label">State:</label>
                            <Select
                                id="state"
                                name="state"
                                options={states}
                                className="form-select"
                                onChange={handleStateChange}
                                value={selectedState}
                            />

                            <label htmlFor="city" className="form-label">City:</label>
                            <Select
                                id="city"
                                name="city"
                                options={cities}
                                className="form-select"
                                onChange={handleCityChange}
                                value={selectedCity}
                            />


                            <label htmlFor="pincode" className="form-label">Pincode/ZIP Code:</label>
                            <input
                                type="text"
                                id="pincode"
                                name="pincode"
                                className="form-input"
                                value={formData.pincode}
                                onChange={handleInputChange}
                                required
                            />

                            <label htmlFor="contact_no" className="form-label">Contact Number :</label>
                            <PhoneInput
                                placeholder="Enter phone number"
                                value={phone}
                                className="form-input"
                                onChange={handlePhoneChange}
                            />
                            <button type="submit" className="add_address_button">Update</button>
                        </form>
                    </section>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditProfileModal