import React from 'react'
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Categorysection from './Categorysection';
import { Person, Cart, House, Search, Heart } from 'react-bootstrap-icons';
import './Header.css'
import { useState } from 'react';
import LoginModal from './LoginModal';
import SignupModal from './SignupModal';
import CartModal from './CartModal';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import loginimage from '../../images/amazing gems and jewellery png with name.png';
import SearchBox from './SearchBox';
import { useLocation } from 'react-router-dom';
import loginimage2 from '../../images/croped amj.png'

const Header = () => {

    const cookies = new Cookies();
    const cookieUserId = cookies.get('user_id') ? cookies.get('user_id') : 'hello';
    const location = useLocation();
    // Check if the path is '/'
    const isLandingPage = location.pathname === '/';
    const navigate = useNavigate();
    const [alert, setAlert] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false); // New state for menu visibility
    const [visibleColumns, setVisibleColumns] = useState({
        contactUs: false,
      });

    // Function to check if token exists in localStorage
    const checkAuthToken = () => {
        const token = localStorage.getItem('token');
        setIsLoggedIn(!!token); // Convert token to boolean
    };

    useEffect(() => {

        checkAuthToken();

        // Add event listener to handle storage changes
        window.addEventListener('storage', checkAuthToken);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('storage', checkAuthToken);
        };
    });

    useEffect(() => {
        if (cookieUserId === 'hello') {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            cookies.remove('user_id');
        }
    }, [cookieUserId]);

    useEffect(() => {
        if (alert) {
            // Set a timer to clear the alert after 1 second
            const timer = setTimeout(() => {
                setAlert(null);
            }, 1000);

            // Clean up the timer if the component unmounts or alert changes
            return () => clearTimeout(timer);
        }
    }, [alert]);



    const handleLogout = () => {
        // Remove token from localStorage and update state
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('common_id')
        cookies.remove('user_id');
        cookies.remove('user_id', { path: '/admin' });
        setIsLoggedIn(false);
        Swal.fire({
            title: 'Logout Successful!',
        })
    };

    const [loginShow, setLoginShow] = useState(false);
    const [signupShow, setSignupShow] = useState(false);
    const [showCart, setShowCart] = useState(false);

    const handleClose = () => {
        setLoginShow(false);
        setSignupShow(false);
        setShowCart(false);
    };
    const handleShowLogin = () => setLoginShow(true);
    const handleShowSignup = () => setSignupShow(true);

    const handleShowCart = async () => {
        setShowCart(true)

    };

    const toggleMenu = () => {
        setMenuOpen(prev => !prev);
    };

    const handleShowSearch = () => {
        const hamElement = document.getElementById('ham');
        const cartHomeElement = document.getElementById('carthome');
        const searchElement = document.getElementById('divForSearchPhone');
        const cancleSearchElement = document.getElementById('canclesearch');
        const logoElement = document.querySelector('.logoPhone');


        hamElement.style.display = 'none';  // Show the wishlist
        cartHomeElement.style.display = 'none';  // Show the wishlist
        logoElement.style.display = 'none';
        searchElement.style.display = 'flex';  // Show the wishlist
        cancleSearchElement.style.display = 'inline';  // Show the wishlist
    }
    const handleCloseSearch = () => {
        const hamElement = document.getElementById('ham');
        const cartHomeElement = document.getElementById('carthome');
        const searchElement = document.getElementById('divForSearchPhone');
        const cancleSearchElement = document.getElementById('canclesearch');
        const logoElement = document.querySelector('.logoPhone');


        logoElement.style.display = 'inline';
        hamElement.style.display = 'inline';  // Show the wishlist
        cartHomeElement.style.display = 'flex';  // Show the wishlist
        searchElement.style.display = 'none';  // Show the wishlist
        cancleSearchElement.style.display = 'none';  // Show the wishlist
    }
    // Function to toggle visibility of a specific column
    const toggleColumnVisibility = (column) => {
        setVisibleColumns(prevState => ({
            ...prevState,
            [column]: !prevState[column]
        }));
    };

    return (
        <>
            <div id='divaftermedialogo'>

                <Link to={'/'}><div className="logo"><img src={loginimage2} alt="Login" /></div></Link>

            </div>

            <div className="header">
                <div id='logot' className="logo"><img src={loginimage} alt="Login" /></div>


                <div className='divForSearch'>
                    <SearchBox />
                </div>

                <div className="nav">
                    <div className="nav-item">
                        <Link id='aboutuslink' to={'/'}><span style={{ borderBottom: '1px solid black' }}>HOME</span></Link>
                    </div>
                    <div className="nav-item">
                        <Link id='aboutuslink' to={'/wishlist'}><span>WISHLIST</span></Link>
                    </div>
                    <div className="nav-item">
                        <Person size={15} />
                        <span>
                            <Dropdown>
                                <Dropdown.Toggle className="custom-dropdown-toggle" id="dropdown-basic">
                                    {isLoggedIn ? (localStorage.getItem('user_name')) : ('ACCOUNT')}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {isLoggedIn ? (
                                        <>
                                            <Dropdown.Item id='accountdisc'>My Account</Dropdown.Item>
                                            <Dropdown.Item id='logutbut'><Link style={{ textDecoration: 'none', color: 'black' }} to={'/check/profile'}>Profile</Link></Dropdown.Item>
                                            <Dropdown.Item id='logutbut' onClick={handleLogout}>Logout</Dropdown.Item>
                                        </>
                                    ) : (
                                        <>
                                            <Dropdown.Item id='accountdisc'>My Account</Dropdown.Item>
                                            <Dropdown.Item id='accountdisc2'>Login to access your account</Dropdown.Item>
                                            <div id='x'>
                                                <Dropdown.Item id='lsbut' onClick={handleShowLogin} as="a"  >Login</Dropdown.Item>
                                                <Dropdown.Item id='lsbut' onClick={handleShowSignup} as="a"  >Sign up</Dropdown.Item>
                                            </div>
                                        </>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </span>
                    </div>

                    <div className="nav-item" onClick={handleShowCart}>
                        <Cart size={15} />
                        <span style={{ marginLeft: '4px' }} >CART</span>
                    </div>
                </div>
            </div>

            <div className="headerPhone">

                <div onClick={toggleMenu} id='ham'>☰</div>
                {menuOpen && (
                    <div className="menu-overlay">
                        <button className="close-button" onClick={toggleMenu}>✖</button>
                        <div className="menu-content">
                            <div className="navphone">
                                <div>
                                    <span>

                                        {isLoggedIn ? (
                                            <>
                                                <div id='phoneMenu'>
                                                    <div id='lsbut'><Link onClick={toggleMenu} style={{ textDecoration: 'none', color: 'white' }} to={'/check/profile'}>Profile {localStorage.getItem('user_name')}</Link></div>
                                                </div>

                                            </>
                                        ) : (
                                            <>

                                                <div id='phoneMenu'>
                                                    <div id='lsbut' onClick={handleShowLogin} as="a">Login</div>
                                                    <div id='lsbut' onClick={handleShowSignup} as="a">Sign up</div>
                                                </div>

                                            </>
                                        )}
                                    </span>
                                </div>
                                <div className="nav-itemphone">
                                    <Link onClick={toggleMenu} className='link' id='aboutuslink' to={'/aboutus'}><span>ABOUT US</span></Link>
                                </div>
                                <div className="nav-itemphone">
                                    {/* Contact Us section */}
                                    <Link onClick={() => toggleColumnVisibility('contactUs')} className='link' id='aboutuslink'><span>CONTACT US</span></Link>
                                    <div className={`footer-column ${visibleColumns.contactUs ? 'active' : ''}`}>
                                       
                                        
                                        <a href="mailto:vardhan00harsh@gmail.com" className="header-contact_us-link"> Write e-mail to Us</a>
                                        <a href="tel:+919351072962" className="header-contact_us-link">Call for Help</a>
                                        <a
                                            href="https://wa.me/+919351072962"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="header-contact_us-link"
                                        >
                                             Chat with us on WhatsApp
                                        </a>
                                    </div>
                                </div>
                                <div className="nav-itemphone">
                                    <Link onClick={toggleMenu} className="link" id='aboutuslink' to={'/tac'}><span>TERMS AND CONDITIONS</span></Link>
                                </div>
                                <div>
                                    {isLoggedIn &&
                                        <div id='phoneMenu'>
                                            <div id='lsbut' onClick={handleLogout}>Logout</div>
                                        </div>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <Link to={'/'}><div className="logoPhone"><img src={loginimage2} alt="Login" /></div></Link>

                <div className='divForSearch' id='divForSearchPhone'>
                    <SearchBox />
                    <button onClick={handleCloseSearch} id='canclesearch' style={{ color: 'black' }}>✖</button>
                </div>

                <div id='carthome'>
                    <div className="search" onClick={handleShowSearch}>
                        <Search size={18} />
                    </div>
                    <Link to={'/'} style={{ textDecoration: 'none' }}>
                        <House size={18} color="#333" />
                    </Link>
                    <Link to={'/wishlist'} style={{ textDecoration: 'none' }}>
                        <Heart size={17} color='#333' />
                    </Link>
                    <div className="phonecart" onClick={handleShowCart}>
                        <Cart size={18} />
                    </div>
                </div>
            </div>
            {!isLandingPage ?
                <Categorysection />
                :
                <div id='categoriesPcView'>
                    <Categorysection />
                </div>
            }
            <LoginModal show={loginShow} onClose={handleClose} />
            <SignupModal show={signupShow} onClose={handleClose} />
            <CartModal show={showCart} onClose={handleClose} />
        </>
    )
}

export default Header