import { useState, useEffect } from 'react';

const useTAC = () => {
    const [TAC, setTAC] = useState([]);
    const [load, setLoad] = useState(true);
    const [err, setErr] = useState(null);

    const fetchTAC = async () => {
        try {
            const response = await fetch(`https://backend.amjgems.com/api/terms`, {
                method: 'GET',
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setTAC(data.terms_and_conditions);
            setErr(null);
        } catch (error) {
            setErr(error.message);
        } finally {
            setLoad(false);
        }
    };

    useEffect(() => {
        fetchTAC();
    }, []);

    return { TAC, load, err, fetchTAC };
}

export default useTAC;
