import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { HeartFill } from 'react-bootstrap-icons';
import LoginModal from '../Mainpage/LoginModal';
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
import useFetchWishlist from '../Admin/useFetchWishlist';
// Css in Header.css file
const cookies = new Cookies();

const Search = () => {
    const { query } = useParams();
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [loginShow, setLoginShow] = useState(false);
    const [lastPage, setLastPage] = useState();
    const [currentPage, setCurrentPage] = useState();
    const { wishlistElements, fetchWishlist } = useFetchWishlist();

    const handleShowLogin = () => setLoginShow(true);
    const handleClose = () => {
        setLoginShow(false);
    };

    const fetchSearchProducts = async (pageNumber = 1) => {
        try {
            const response = await fetch(`https://backend.amjgems.com/api/products/search?keyword=${query}&page=${pageNumber}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setLastPage(data.meta.last_page);
            setCurrentPage(data.meta.current_page)
            setProducts(data.products.data);
        } catch (error) {
            navigate('/allproducts');

        } finally {

        }
    };

    useEffect(() => {
        if (query) {
            fetchSearchProducts();
        }
        else {
            navigate('/allproducts');
        }

    }, [query]);

    const generatePageNumbers = (currentPage, lastPage) => {
        const pages = [];
        const maxVisiblePages = 5;

        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(lastPage, currentPage + 2);

        // Add the first page
        if (startPage > 1) {
            pages.push(1);
            if (startPage > 2) pages.push('...'); // Add ellipsis if needed
        }

        // Add range of pages
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        // Add the last page
        if (endPage < lastPage) {
            if (endPage < lastPage - 1) pages.push('...'); // Add ellipsis if needed
            pages.push(lastPage);
        }

        return pages;
    };

    const handlePageClick = (pageNumber) => {
        fetchSearchProducts(pageNumber); // Fetch products for the selected page
    };


    const wishlistAdd = async (product_code) => {

        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') || '';
        try {
            const response = await fetch(`https://backend.amjgems.com/api/add-whishlist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: parseInt(user_id), product_code: product_code }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            const wishlistElement = document.querySelector('.' + product_code);

            if (data.success) {
                wishlistElement.style.color = 'red';  // Hide the wishlist
            }
            else if (data.remove) {
                wishlistElement.style.color = '#11111199';  // Show the wishlist
            }

        } catch (error) {
            if (token || user_id) {
                Swal.fire({
                    title: 'There seems to be an issue adding the item to the wishlist.',
                })
            }
            else {
                localStorage.clear();
                cookies.remove('user_id');
                handleShowLogin();
            }
        }
    }

    return (
        <>
            <div id="filtercontroller">
                <div id='home'>
                    <Link className="link" onClick={() => navigate(-1)}>
                        Back
                    </Link>
                    <div id="searchedItem">
                            Your Search : {query}
                    </div>
                </div>
            </div>
            <section id='midcontent'>
                {products.map((product) => (

                    <div key={product.id} class="card">

                        <button
                            style={wishlistElements.includes(product.product_code) ? { color: 'red' } : { color: '#11111199' }}
                            onClick={() => { wishlistAdd(product.product_code) }}
                            id='wishlistoutside'
                            className={product.product_code}
                        >
                            <HeartFill size={20} /> {/* Use the filled heart icon */}
                        </button>
                        <Link to={`/productshow/${product.product_code}`}>
                            <div className='image-container'>
                                <img src={`https://backend.amjgems.com/${product.product_image_1}`} alt="Captivating Elegance Diamond Earrings" />
                            </div>
                            <h3>{product.product_name}</h3>
                            <p class="price">${(product?.variant?.price * (1 - product.product_discount / 100)).toFixed(2)}{product?.product_discount && (<span class="price-cut">${product?.variant?.price}</span>)}</p>
                            {product?.variant?.quantity === 0 && product?.single_product > 0 ?
                                (<p class="discount">Out of stock!</p>) :
                                (<>{product?.variant?.quantity < 15 && product?.single_product > 0 && <p class="discount">Only {product?.variant?.quantity} left!</p>}</>)
                            }
                        </Link>
                    </div>
                ))}
            </section>
            <div className="pagination">
                {generatePageNumbers(currentPage, lastPage).map((pageNumber, index) => (
                    <button
                        key={index}
                        onClick={() => typeof pageNumber === 'number' && handlePageClick(pageNumber)}
                        disabled={pageNumber === currentPage} // Optionally disable the current page button
                    >
                        {pageNumber}
                    </button>
                ))}
            </div>
            <LoginModal show={loginShow} onClose={handleClose} />
        </>
    )
}

export default Search