import React from 'react'
import useTAC from './useTAC';
import Loader from '../Loader/Loader';
import { Link } from 'react-router-dom';

const TAC = () => {
    const { TAC, load, err, fetchTAC } = useTAC();

    if (load) {
        return <div className="loader"><Loader /></div>;
    }

    if (err) {
        return <div className="error">Error: {err.message}</div>;
    }
    return (
        <section id='aboutussection'>
            <div class="breadcrumb">
                <Link to='/'>Home</Link> &gt; <a href="#">About Us</a>
            </div>
            <div className="about-us-container">
                <div className="about-us-header">
                    <h1>Terms And Conditions</h1>
                </div>
                <div className="about-us-content">
                    <p dangerouslySetInnerHTML={{ __html: TAC }} />
                </div>
            </div>
        </section>
    )
}

export default TAC