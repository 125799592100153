import React from 'react'
import { useEffect, useState } from 'react';

const useFetchWishlist = () => {
    const [wishlistElements, setWishlistElements] = useState([]);
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';

    useEffect(() => {
        if (user_id && token) {
            fetchWishlist();
        }
    }, [])

    const fetchWishlist = async () => {
        try {
            const response = await fetch(`https://backend.amjgems.com/api/whishlist-product-code/${user_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
            }); // Replace with your API endpoint

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const res = await response.json();
            setWishlistElements(res.product_code);
        }
        catch (error) {
        }
    }
    return { wishlistElements, fetchWishlist };
}

export default useFetchWishlist