import React from 'react'
import './Policy.scss'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Loader from '../Loader/Loader'

const Policy = () => {
    const [policies, setPolicy] = useState([]);
    const [load, setLoad] = useState(true);
    const [err, setErr] = useState(null);
    const fetchPolicy = async () => {

        try {
            const response = await fetch(`https://backend.amjgems.com/api/policy`, {
                method: 'GET',
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setPolicy(data.policy);
            setErr(null);
        } catch (error) {
            setErr(error.message);
        } finally {
            setLoad(false);
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchPolicy();
    }, [])

    if (load) {
        return <div className="loader"><Loader /></div>;
    }

    if (err) {
        return <div className="error">Error: {err.message}</div>;
    }

    return (
        <section id='policyPageSection'>
            <div class="breadcrumb">
                <Link to='/'>Home</Link> &gt; <a href="#">Shipping & Returns & Privacy</a>
            </div>

            <div class="containerPolicy">
                <div id='heading1'>SHIPPING & RETURNS & PRIVACY</div>
                {policies.length > 0 && policies.map((policy) => (
                    <>
                        <div id='heading2'>{policy.policy_name}</div>
                        <ol class="policy-list">
                            
                                <div dangerouslySetInnerHTML={{ __html: policy?.policy_content }} />
                            
                        </ol>
                    </>
                ))}
            </div>
        </section>
    )
}

export default Policy