import React, { useEffect } from 'react'
import './Landingpage.scss'
import { useState } from "react";
import { Carousel } from "react-bootstrap";
import useCarousel from '../Admin/useCarousel';
import useCategories from '../Admin/useCategories';
import Loader from '../Loader/Loader';
import Home from '../Content/Home';
import { Link } from 'react-router-dom';
import Categorysection from '../Mainpage/Categorysection';
import loginimage from '../../images/croped amj.png';
import loginimage2 from '../../images/croped amj.png'
import { HandThumbsUpFill, StarFill } from 'react-bootstrap-icons';
import { FaUnderline } from 'react-icons/fa';

const Landingpage = () => {

    const { categories, loding, error, fetchcategories } = useCategories();
    const [index, setIndex] = useState(0);
    const { carousels, loading, errorc, fetchCarousel } = useCarousel();
    const [landingData, setLandingData] = useState({});
    const [showFullAnnouncement, setShowFullAnnouncement] = useState(false);

    const fetchLandingData = async () => {
        try {
            const response = await fetch('https://backend.amjgems.com/api/home-admin', {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setLandingData(data);
        } catch (error) {
            console.error('Failed to fetch landing data:', error);
        }
    };

    useEffect(() => {
        fetchLandingData();
    }, [])



    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    if (loading || loding) {
        // Render nothing or a loading indicator while the check is in progress
        return <div><Loader /></div>;
    }
    return (
        <>
            <div id='landingpage'>
                <div id='carouselDiv'>
                    <Carousel activeIndex={index} onSelect={handleSelect}>
                        {carousels.map((carousel, index) => (
                            <Carousel.Item key={index}>
                                <img
                                    className="d-block w-100"
                                    src={`https://backend.amjgems.com/${carousel.image_path}`}
                                    alt="First slide"
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                    <div id='companyName'>
                        {landingData?.admin?.home_logo ?
                            <Link to={'/'}><div className="companyPhoto"><img src={`https://backend.amjgems.com/${landingData?.admin?.home_logo}`} alt="Login" /></div></Link> :
                            <Link to={'/'}><div className="companyPhoto"><img src={loginimage2} alt="Login" /></div></Link>
                        }

                    </div>
                    <div id='likes'>
                        <HandThumbsUpFill size={13} color='#333' />
                        <div>{landingData?.admin?.likes} Likes</div>
                    </div>
                </div>

                <section id='Detailslpage'>
                    {landingData?.admin?.home_logo ?
                        <div className="logoDetails"><img src={`https://backend.amjgems.com/${landingData?.admin?.home_logo}`} alt="Login" /></div> :
                        <div className="logoDetails"><img src={loginimage} alt="Login" /></div>
                    }

                    <div id='DetailsContent' >
                        <h3>Amazing Gems And Jewellers</h3>
                        <div id='desclpage'>See all kinds of stones with superb Quality.</div>
                        <div id='desclpage'>{landingData?.admin?.city}</div>
                        <div id='sales'>
                            <div>
                                {landingData?.admin?.sales} sales |
                            </div>
                            <div>
                                <StarFill size={10} />
                                <StarFill size={10} />
                                <StarFill size={10} />
                                <StarFill size={10} />
                                <StarFill size={10} />
                            </div>
                        </div>
                    </div>
                </section>
                {landingData?.admin?.home_announcement &&
                    <section id='landingAnnouncement'>
                        <div><u>Announcement</u></div>
                        {!showFullAnnouncement ?
                            <p>{landingData?.admin?.home_announcement.substring(0, 100)}
                                <div className="toggle-description" onClick={() => setShowFullAnnouncement(!showFullAnnouncement)}>
                                    Show More
                                </div>
                            </p>
                            :
                            <p>{landingData?.admin?.home_announcement}
                                <div className="toggle-description" onClick={() => setShowFullAnnouncement(!showFullAnnouncement)}>
                                    Show Less
                                </div>
                            </p>
                        }


                    </section>
                }
                <div id='borderLandingPage'></div>
                <section id='categoriesPhomeView'>
                    <div id='brouseCategoriesContent' >
                        <div id='desclpage'>Browse categories.</div>
                    </div>
                    <Categorysection />
                </section>

                <section id='landingSec'>
                    <div id='categoryPart'>
                        <div id='headinglpage' >
                            <h2>Categories</h2>
                            <div id='desclpage'>Browse categories.</div>
                        </div>
                        <div id='categoriesLinksDiv'>
                            <div id='linkdiv'><Link className='linkLandingpage' to={`/allproducts`}>All Products</Link></div>
                            {categories.map((category) => (
                                <div id='linkdiv' key={category.category_code}><span style={{ color: category.color_code }} id='color'>●</span><Link className='linkLandingpage' to={`/category?category=${category.category_code}`}>{category.category_name}</Link></div>
                            ))}
                        </div>
                    </div>
                    <div id='mobileViewProductsDiv'>
                        <Home />
                    </div>
                </section>
            </div>
        </>
    )
}

export default Landingpage