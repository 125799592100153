// src/components/LoginModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Carousel } from 'react-bootstrap';
import loginimage from '../../images/amazing gems and jewellery png with name.png';
import Cookies from 'universal-cookie'; // Ensure this is installed
import { jwtDecode } from 'jwt-decode'; // Correct import
import Swal from 'sweetalert2';
import ForgetPassModel from './ForgetPassModel';
import { Person, ArrowRightCircle } from 'react-bootstrap-icons'; // Importing icons
import SignupModal from './SignupModal';
import GuestLoginModal from './GuestLoginModal';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeSlash } from 'react-bootstrap-icons'; // Import the icons
import './LoginModal.scss'

const LoginModal = ({ checkout = '', show, onClose }) => {

    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();


    const [email, setemail] = useState('');
    const [pass, setpass] = useState('');

    const [signupShow, setSignupShow] = useState(false);
    const [GuestLoginShow, setGuestLoginShow] = useState(false);

    const handleSignupClose = () => {
        setSignupShow(false);
    };
    const handleShowSignup = () => {
        onClose();
        setSignupShow(true);
    }

    const handleSubmitlogin = async (event) => {
        event.preventDefault();
        const common_id = localStorage.getItem('common_id') ? localStorage.getItem('common_id') : '';

        try {
            const response = await fetch('https://backend.amjgems.com/api/login', { // Replace with your API endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ common_id: common_id, email: email, password: pass }),
            });
            const data = await response.json();

            // Store token and user ID in local storage
            if (data.token && data.user_id) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user_id', data.user_id);
                localStorage.setItem('user_name', data.user_name);

                const decodedToken = jwtDecode(data.token);
                const expiryDate = new Date(decodedToken.exp * 1000); // Convert from seconds to milliseconds

                // Set cookie with user ID
                const cookies = new Cookies();
                cookies.set('user_id', data.user_id, { expires: expiryDate });

                onClose();
                if (checkout) {
                    navigate('/check/checkoutpage');
                }
                Swal.fire({
                    title: 'Login Successful!',
                })
            } else {
                console.error('Token or UserId missing in response');
                onClose();
                Swal.fire({
                    title: 'Login Failed ! ' + (data.message || 'Invalid Credentials'),
                })
            }

        } catch (error) {
            // console.error('Error adding category:', error);
        }
    };

    const [ForgetPassShow, setForgetPassShow] = useState(false);

    const handleClose = () => {
        setForgetPassShow(false);
        setGuestLoginShow(false);
    };
    const handleForgetPassModel = () => {
        onClose();
        setForgetPassShow(true);
    }

    const handleShowGuestLogin = () => {
        onClose();
        setGuestLoginShow(true);
    }

    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="popupcontainerls">

                        <img id='loginsignupimage' src={loginimage} alt="Login" />


                        <div className="guest-option">
                            <button class="btn-guest" onClick={handleShowGuestLogin}><Person size={20} /> Continue as Guest</button>
                        </div>


                        <div id='or'>Or</div>
                        <div className="popupcontainer">
                            <h1>Login</h1>
                            <form onSubmit={handleSubmitlogin}>
                                <input
                                    className='loginsignupinput'
                                    type="email"
                                    placeholder='E-mail'
                                    value={email}
                                    onChange={(e) => {
                                        setemail(e.target.value)
                                    }}
                                    required
                                />
                                <input
                                    className='loginsignupinput'
                                    type={showPassword ? "text" : "password"}
                                    placeholder='Password'
                                    value={pass}
                                    onChange={(e) => {
                                        setpass(e.target.value)
                                    }}
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="show-password-btn"
                                    aria-label={showPassword ? "Hide password" : "Show password"}
                                >
                                    {showPassword ? (
                                        <EyeSlash size={20} className="password-icon" />
                                    ) : (
                                        <Eye size={20} className="password-icon" />
                                    )}
                                </button>

                                <div id='butdiv'>
                                    <button className='loginsignupbutton' type='submit'>Submit</button>
                                </div>
                            </form>
                            <button id='forgetpassbutton' onClick={handleForgetPassModel}>Forget Password !</button>
                        </div>

                        <div id='or'>Or</div>

                        <div class="signup-option">
                            <button class="btn-signup" onClick={handleShowSignup}><ArrowRightCircle size={20} /> Sign Up</button>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
            <ForgetPassModel show={ForgetPassShow} onClose={handleClose} />
            <SignupModal show={signupShow} onClose={handleSignupClose} />
            <GuestLoginModal show={GuestLoginShow} onClose={handleClose} />
        </>
    );
};

export default LoginModal;
