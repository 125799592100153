import React, { useEffect, useState } from 'react'
import './Wishlist.scss'
import { Link } from 'react-router-dom';

const Wishlist = () => {

    const [wishlistData, setWishlistData] = useState([]);

    useEffect(() => {
        fetchWishlist();
    }, [])


    const fetchWishlist = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await fetch('https://backend.amjgems.com/api/fetch-whishlist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify({ user_id: localStorage.getItem('user_id') }),
            });
            const data = await response.json();
            setWishlistData(data.data)
        } catch (error) {
            console.error('Error checking admin status:', error);
        }
    }

    const removeFromWishlist = async (e) => {
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') || '';
        try {
            const response = await fetch(`https://backend.amjgems.com/api/add-whishlist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: parseInt(user_id), product_code: e.target.id }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            fetchWishlist();
        }
        catch (error) {

        }
    }

    return (
        <div class="wishlist-container">
            <h1 class="wishlist-title">My Wishlist</h1>
            <table class="wishlist-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Product Name</th>
                        <th>Unit Price</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {wishlistData.map((product) => (
                        <tr>
                            <td><button onClick={removeFromWishlist} id={product.product_code} class="remove-btn">×</button></td>
                            <Link className='link' to={`/productshow/${product.product_code}`}>
                                <td class="product-name">
                                    <img src={`https://backend.amjgems.com/${product.product_image_1}`} alt="Product Split" class="product-image-wishlist" />
                                    {product.product_name}
                                </td>
                            </Link>

                            <td>
                                <p class="price">${(product?.variant?.price * (1 - product.product_discount / 100)).toFixed(2)}{product?.product_discount && (<span class="price-cut">${product?.variant?.price}</span>)}</p>
                                {product?.variant?.quantity === 0 && product?.single_product > 0 ?
                                    (<p class="discount">Out of stock!</p>) :
                                    (<>{product?.variant?.quantity < 15 && product?.single_product > 0 && <p class="discount">Only {product?.variant?.quantity} left!</p>}</>)
                                }</td>

                            <td>
                                <Link to={`/productshow/${product.product_code}`}>
                                    <button class="add-to-cart">View Product</button>
                                </Link>
                            </td>


                        </tr>
                    ))}

                </tbody>
            </table>
        </div >
    )
}

export default Wishlist