import React from 'react'
import './AnnoumcementBar.scss'
import { useState , useEffect } from 'react';

const AnnouncementBar = () => {
    const [announcement, setAnnouncement] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchAnnouncement = async () => {
        try {
            const response = await fetch('https://backend.amjgems.com/api/announcement', {
                method: 'GET',
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setAnnouncement(data)
            setError(null);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAnnouncement();
    }, []);

    if(announcement.success){

        return (
            <div class="announcement-bar">
                <div class="announcement-content">
                    <p>🎉 {announcement.announcement} 🎉</p>
                </div>
            </div>
        )
    }
    else{
        return null
    }

    
}

export default AnnouncementBar