import React from 'react'
import { FaWhatsapp } from 'react-icons/fa';
import './WhatsApp.css'; // Add the styling

const WhatsAppIcon = () => {
    return (
        <div className="whatsapp-icon-container">
            <a
                href="https://wa.me/+919351072962"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
            >
                <FaWhatsapp size={40} color="green" />

            </a>
        </div>
    )
}

export default WhatsAppIcon