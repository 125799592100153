// src/components/LoginModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Carousel } from 'react-bootstrap';
import './Cart.scss'
import useCartContant from './useCartContant';
import { useNavigate } from 'react-router-dom';
import { Cart } from 'react-bootstrap-icons';
import LoginModal from './LoginModal';

const CartModal = ({ show, onClose }) => {

    const [loginShow, setLoginShow] = useState(false);

    const handleClose = () => {
        setLoginShow(false);
    };
    const handleShowLogin = () => setLoginShow(true);

    const navigate = useNavigate();

    const { cartproducts, loading, error, fetchCartProducts } = useCartContant();

    useEffect(() => {
        if (show) {
            fetchCartProducts();
        }
    }, [show]);

    // Initialize state for quantity

    // Handler function to increment quantity
    const updateQuantity = async (variantid, productCode, action) => {
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') || '';
        const common_id = localStorage.getItem('common_id') ? localStorage.getItem('common_id') : '';

        try {
            const response = await fetch(`https://backend.amjgems.com/api/cart/quantity`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ common_id: common_id, user_id: parseInt(user_id), variant_id: variantid, product_code: productCode, action }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            fetchCartProducts();
        } catch (error) {
        }
    };

    const removeProduct = async (varientid, productcode) => {
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        const common_id = localStorage.getItem('common_id') ? localStorage.getItem('common_id') : '';

        try {
            const response = await fetch(`https://backend.amjgems.com/api/cart/delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ common_id: common_id, user_id: parseInt(user_id), product_code: productcode, variant_id: varientid }),
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            fetchCartProducts();
        } catch (error) {

        } finally {

        }
    }

    const removeProductall = async (e) => {

        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        const common_id = localStorage.getItem('common_id') ? localStorage.getItem('common_id') : '';

        try {
            const response = await fetch(`https://backend.amjgems.com/api/cart/delete-all`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ common_id: common_id, user_id: parseInt(user_id) }),
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            fetchCartProducts();
        } catch (error) {

        } finally {

        }
    }

    const handleCheckout = () => {
        onClose();
        if (!localStorage.getItem('token') && !localStorage.getItem('user_id')) {
            setLoginShow(true);
        }
        else {
            navigate('/check/checkoutpage');
        }
    }

    return (
        <>
            <Modal show={show} onHide={onClose} className="custom-modal">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className='modalbody'>
                    <div class="headercart">
                        <h1>YOUR CART</h1>
                        <p>{cartproducts.cart_items.length} Items</p>
                    </div>

                    <div class="container">
                        <div class="left-col">
                            {cartproducts?.cart_items?.map((cartproduct, index) => (

                                <div key={index} class="product-item">
                                    <img className='img-thumbnail' src={`https://backend.amjgems.com/${cartproduct.product.product_image_1}`} alt="No Items" class="product-img" />
                                    <div class="product-info">
                                        <div class="product-title">{cartproduct.product.product_name}</div>
                                        <div class="product-details">
                                            <span>Price : $ {cartproduct.product_total_price}</span>
                                        </div>
                                        <div id='quantity'>
                                            <span>Quantity</span>
                                            <input
                                                type="number"
                                                value={cartproduct.product_cart_quantity}
                                                readOnly
                                            />
                                            <button onClick={() => updateQuantity(cartproduct.variant.id, cartproduct.product.product_code, 'increment')}>+</button>
                                            <button onClick={() => updateQuantity(cartproduct.variant.id, cartproduct.product.product_code, 'decrement')}>-</button>
                                        </div>
                                        {cartproduct.variant.size && cartproduct.variant.pieces ?
                                            <div id='varientDropdown' style={{ marginBottom: '2px', marginTop: '0px' }}>
                                                <select>
                                                    <option key={cartproduct.variant.id} value={cartproduct.variant.id}>
                                                        {cartproduct.variant.size} - {cartproduct.variant.pieces} pieces
                                                    </option>
                                                </select>
                                            </div> :
                                            null
                                        }

                                        <div id='quantity_adjusted'>{cartproduct.adjustment_message}</div>
                                    </div>
                                    <div><button id={cartproduct?.variant?.id} onClick={() => removeProduct(cartproduct?.variant?.id, cartproduct.product.product_code)} className='btn btn-danger'>Remove</button></div>
                                </div>

                            ))}
                        </div>
                        {cartproducts?.cart_items.length > 0 ?
                            <div class="right-col">

                                {cartproducts.cart_items.map((cartproduct, index) => {
                                    // Calculate and round the discounted price
                                    const discountedPrice = (cartproduct.variant.price * (1 - cartproduct.product.product_discount / 100)).toFixed(2);

                                    return (
                                        <div id="smallborder" key={index}>
                                            <div className="price">
                                                $ {(cartproducts.flat_discount ? discountedPrice : cartproduct.variant.price)} * {cartproduct.product_cart_quantity} =
                                                <span style={{ fontWeight: 'bold' }}> $ {cartproduct.product_total_price}</span>
                                                {cartproducts?.flat_discount &&
                                                    <div id="discountcc" style={{ margin: '0px' }}>
                                                        [Price including discount <span>{cartproduct.product.product_discount}%</span>]
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    );
                                })}

                                <div id='border'></div>
                                <div class="total"> Total : $ {cartproducts.cart_sum}</div>
                                {cartproducts?.coupon_applied !== "N/A" && cartproducts?.coupon_applied?.discount_coupon && (
                                    <div className="cart-free-shipping">Coupon applied: {cartproducts.coupon_applied.discount_coupon} - {cartproducts.coupon_applied.discount_percent}%</div>
                                )}
                                {cartproducts?.coupon_applied?.discount_coupon &&
                                    <>
                                        <div id='border'></div>
                                        <div className="price_after_discount"> Price after Coupon Discount : $ {cartproducts.discounted_price}</div>
                                    </>
                                }

                                {cartproducts?.tax_percent !== undefined && <div class="tax"> Tax : % {cartproducts?.tax_percent}</div>}
                                {cartproducts?.free_shipping ?
                                    <div class="cart-free-shipping"> Free delivery applied</div> :
                                    <div class="cart-standard-shipping"> Standard Shipping Price : $ {cartproducts?.standard_shipping_price}</div>

                                }
                                <div id='border'></div>
                                <div class="total" style={{ marginBottom: '15px' }}> Sub Total : $ {cartproducts.subtotal_after_tax}</div>

                                <div><button onClick={removeProductall} className='btn btn-danger'>Clear all Cart</button></div>
                                <div id='checkoutdiv'><button onClick={handleCheckout} id='checkout' className='btn btn-success'>Checkout</button></div>
                            </div>
                            :
                            <div id='emptyCart'>
                                <div><Cart size={33} /></div>
                                <div id='emptyCartText'>Empty Cart !</div>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            <LoginModal checkout={true} show={loginShow} onClose={handleClose} />
        </>
    );
};

export default CartModal;
