import React, { useState } from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';

function Footer() {
  // State to manage visibility of each footer column
  const [visibleColumns, setVisibleColumns] = useState({
    usefulLinks: false,
    information: false,
    contactUs: false,
    appDownload: false,
    aboutUs: false,  // New state for "About Us" section
    policies: false, // New state for "Policies" section
  });

  // Function to toggle visibility of a specific column
  const toggleColumnVisibility = (column) => {
    setVisibleColumns(prevState => ({
      ...prevState,
      [column]: !prevState[column]
    }));
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Terms and Conditions section */}
        <div className={`footer-column ${visibleColumns.usefulLinks ? 'active' : ''}`}>
          <h3 className="footer-title" onClick={() => toggleColumnVisibility('usefulLinks')}>
            Terms and Conditions
          </h3>
          <Link className="footer-link" to={'/tac'}><span>Terms and Conditions</span></Link>
        </div>

        {/* Information section */}
        <div className={`footer-column ${visibleColumns.information ? 'active' : ''}`}>
          <h3 className="footer-title" onClick={() => toggleColumnVisibility('information')}>
            Information
          </h3>
          <Link to='/policy' className="footer-link">Policies</Link>
          <a href="#" className="footer-link">About Amazing Gems & Jewellers</a>
        </div>

        {/* Contact Us section */}
        <div className={`footer-column ${visibleColumns.contactUs ? 'active' : ''}`}>
          <h3 className="footer-title" onClick={() => toggleColumnVisibility('contactUs')}>
            Contact Us
          </h3>
          <a href="mailto:vardhan00harsh@gmail.com" className="footer-link">Write e-mail to Us</a>
          <a href="tel:+919351072962" className="footer-link">Call for Help</a>
          <a
            href="https://wa.me/+919351072962"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
            Chat with us on WhatsApp
          </a>
        </div>

        {/* About Us section */}
        <div className={`footer-column ${visibleColumns.aboutUs ? 'active' : ''}`}>
          <h3 className="footer-title" onClick={() => toggleColumnVisibility('aboutUs')}>
            About Us
          </h3>
          <Link className="footer-link" to="/aboutus"><span>About Us</span></Link>
        </div>
      </div>

      <div className="copyright">
        &copy; 2024 Amazing Gems & Jewellers. All Rights Reserved.
      </div>
    </footer>
  );
}

export default Footer;
