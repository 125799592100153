import React from 'react'
import './midcontent.scss'
import useProducts from '../Admin/useProducts';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Filtercontroller from './Filtercontroller';
import { Link } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { useLocation } from 'react-router-dom';
import { HeartFill } from 'react-bootstrap-icons';
import LoginModal from '../Mainpage/LoginModal';
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
import useFetchWishlist from '../Admin/useFetchWishlist';

const cookies = new Cookies();

const Home = () => {

  const location = useLocation(); // Get current location
  const isRootPath = location.pathname === '/'; // Check if we're on the root path
  const queryParams = new URLSearchParams(location.search);
  const cut = queryParams.get('cut');
  const shape = queryParams.get('shape');
  const category_code = queryParams.get('category');

  const [filters, setFilters] = useState({
    category_code: category_code || '',
    cut: cut || '',
    shape: shape || '',
    priceRange: '',
    min_price: null,
    max_price: null,
    per_page: '',
  });
  const [loginShow, setLoginShow] = useState(false);
  const { products, currentPage, lastPage, load, err, fetchproducts } = useProducts(filters);
  const { wishlistElements, fetchWishlist } = useFetchWishlist();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [products]);

  useEffect(() => {
    // Reset filters if we're on the allproducts page

    setFilters(prevFilters => ({
      ...prevFilters,
      category_code: category_code || '', // Default to '' if category_code is null
      cut: cut || '', // Default to '' if cut is null
      shape: shape || '', // Default to '' if shape is null
    }));
  }, [category_code, cut, shape]); // Trigger reset when path changes to allproducts

  useEffect(() => {

    fetchproducts();

  }, [filters]);

  const handleShowLogin = () => setLoginShow(true);
  const handleClose = () => {
    setLoginShow(false);
  };


  const generatePageNumbers = (currentPage, lastPage) => {
    const pages = [];
    const maxVisiblePages = 5;

    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(lastPage, currentPage + 2);

    // Add the first page
    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) pages.push('...'); // Add ellipsis if needed
    }

    // Add range of pages
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Add the last page
    if (endPage < lastPage) {
      if (endPage < lastPage - 1) pages.push('...'); // Add ellipsis if needed
      pages.push(lastPage);
    }

    return pages;
  };

  const handlePageClick = (pageNumber) => {
    fetchproducts(pageNumber); // Fetch products for the selected page
  };


  const wishlistAdd = async (product_code) => {

    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id') || '';
    try {
      const response = await fetch(`https://backend.amjgems.com/api/add-whishlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ user_id: parseInt(user_id), product_code: product_code }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      const wishlistElement = document.querySelector('.' + product_code);

      if (data.success) {
        wishlistElement.style.color = 'red';  // Hide the wishlist
      }
      else if (data.remove) {
        wishlistElement.style.color = '#11111199';  // Show the wishlist
      }

    } catch (error) {
      if (token || user_id) {
        Swal.fire({
          title: 'There seems to be an issue adding the item to the wishlist.',
        })
      }
      else {
        localStorage.clear();
        cookies.remove('user_id');
        handleShowLogin();
      }
    }
  }


  if (load) {
    // Render nothing or a loading indicator while the check is in progress
    return <div><Loader /></div>;
  }

  return (
    <>
      {/* Only render Filtercontroller if we're not on the root path */}
      {!isRootPath && <Filtercontroller />}
      <section id='midcontent'>
        {products.map((product) => (

          <div key={product.id} class="card">

            <button
              style={wishlistElements.includes(product.product_code) ? { color: 'red' } : { color: '#11111199' }}
              onClick={() => { wishlistAdd(product.product_code) }}
              id='wishlistoutside'
              className={product.product_code}
            >
              <HeartFill size={20} /> {/* Use the filled heart icon */}
            </button>
            <Link to={`/productshow/${product.product_code}`}>
              <div className='image-container'>
                <img src={`https://backend.amjgems.com/${product.product_image_1}`} alt="Captivating Elegance Diamond Earrings" />
              </div>
              <h3>{product.product_name}</h3>
              <p class="price">${(product?.variant?.price * (1 - product.product_discount / 100)).toFixed(2)}{product?.product_discount && (<span className="price-cut-home">${product?.variant?.price}</span>)}</p>
              {product?.variant?.quantity === 0 && product?.single_product > 0 ?
                (<p class="discount">Out of stock!</p>) :
                (<>{product?.variant?.quantity < 15 && product?.single_product > 0 && <p class="discount">Only {product?.variant?.quantity} left!</p>}</>)
              }
            </Link>
          </div>
        ))}
      </section >
      <div className="pagination">
        {generatePageNumbers(currentPage, lastPage).map((pageNumber, index) => (
          <button
            key={index}
            onClick={() => typeof pageNumber === 'number' && handlePageClick(pageNumber)}
            disabled={pageNumber === currentPage} // Optionally disable the current page button
          >
            {pageNumber}
          </button>
        ))}
      </div>
      <LoginModal show={loginShow} onClose={handleClose} />
    </>

  )
}

export default Home